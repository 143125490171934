import React from "react";
import { Form, Formik } from "formik";
import { get, map } from "lodash-es";
import Select from "../components/Select";
import { useQueryWorks } from "../api/works";
import Button from "../components/Button";
import { useUpdateCollaborator } from "../api/collaborator";
import { toast } from "react-toastify";
import { usePermissionsChecker } from "../contexts/permissions";



export default function WorkCollaboratorForm({ collaborator }) {
  const { data: works } = useQueryWorks();
  const [updateCollaborator] = useUpdateCollaborator();

    const canEdit = usePermissionsChecker({
        permissions: ["kdix.actions.skill_evaluation.edit_create"],
    });

  return (
    <Formik
      initialValues={{
        work: {
          label: get(collaborator, "work.label", false),
          value: get(collaborator, "work[@id]", false),
        },
      }}
      onSubmit={async (values, actions) => {
        try {
          if(values.work.value && values.work.value !== "") {
            await updateCollaborator({
              id: collaborator.id,
              data: { work: values.work.value },
            });
            toast.success("Mise à jour effectuée avec succès");
          }
        } catch (error) {
          map(get(error, "violations"), (e, idx) => {
            actions.setFieldError(e.propertyPath, e.message);
            if (idx === 0) {
              document
                .getElementsByName(e.propertyPath)[0]
                .scrollIntoView({ block: "center", behavior: "smooth" });
            }
          });
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Select
            label="Poste"
            name="work"
            placeholder={"Sélectionner..."}
            options={map(works, (node) => ({
              label: node.label,
              value: node["@id"],
            }))}
            isDisabled={!canEdit}
            isLoading={!works}
          />
          <div className="flex justify-end">
            <Button
              className={`btn my-8 px-8 ml-auto`}
              disabled={isSubmitting}
              readOnly={!canEdit}
              isSubmitting={isSubmitting}
              isForm={true}
              type="submit"
              textLabel={"Enregistrer"}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}
