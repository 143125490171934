import { filter, flatMap, map } from "lodash-es";
import Select from "./Select";
import React, { useEffect, useState, Component } from "react";
import { components } from "react-select";
import { useDebounce } from "react-use";
import { useQueryCollaboratorsList } from "../api/collaborator";
import { getFirstnameLastnameJob, getFirstnameLastname } from "../utils/names";



const MoreSelectedBadge = ({ items }) => {
  const style = {
    order: 99
  };

  const title = items.join(", ");
  const length = items.length;
  const label = `+ ${length} collaborateur${length !== 1 ? "s" : ""} selectionné${length !== 1 ? "s" : ""}`;

  return (
    <div style={style} title={title}>
      {label}
    </div>
  );
};

const MultiValue = ({ index, getValue, ...props }) => {
  const maxToShow = 6;
  const overflow = getValue()
    .slice(maxToShow)
    .map((x) => x.label);

  return index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null;
};


export default function SelectCollaborator({
  value,
  name,
  label,
  isDisabled = false,
  exclude = null,
  isMulti,
  withJob = true,
  valueId = false,
  allowSelectAll = false,
  displayConcat = false,
  isUser = false,
  filterNoEvaluator = false,
  queryFilters = {},
  addOptions = [],
  ...props
}) {
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [,] = useDebounce(
    () => {
      setDebouncedQuery(query);
    },
    500,
    [query]
  );
  const addFilters = isUser
    ? {
        "exists[linkedUser]": "true",
      }
    : {};
    
  const addFilterEvaluation = filterNoEvaluator
    ? {
        "exists[evaluators]": "true",
    }
    : {};
  const {
    data,
    fetchMore,
    canFetchMore,
    isFetchingMore,
    isFetching,
  } = useQueryCollaboratorsList(
    {
      "order[lastname]": "asc",
      active: "true",
      orSearch_fullname: debouncedQuery,
      ...addFilters,
      ...addFilterEvaluation,
      ...queryFilters,
    },
    false
  );
  const collaborators = flatMap(data, (page) => {
    if (exclude) {
      return filter(page["hydra:member"], (m) => m["@id"] !== exclude);
    }
    return page["hydra:member"];
  });
  


  

  useEffect(() => {
    //Si on doit pouvoir tout sélectionner on doit attendre d'avoir récupéré toutes les entrées
    if (allowSelectAll && !isDisabled) {
      if (
        canFetchMore !== false &&
        isFetchingMore !== true &&
        isFetching !== true
      ) {
        fetchMore();
      }
    }
  }, [
    allowSelectAll,
    isDisabled,
    canFetchMore,
    isFetchingMore,
    isFetching,
    fetchMore,
  ]);
  return (
	  <div>
	  { displayConcat && !isDisabled ? (
	    <Select
	      name={name}
	      label={label}
	      options={[
	        ...map(collaborators, (collaborator) => ({
	          label: withJob ? getFirstnameLastnameJob({ collaborator }, "collaborator") : getFirstnameLastname({ collaborator }, "collaborator"),
	          value: valueId
	            ? collaborator.id
	            : isUser
	            ? collaborator?.linkedUser?.["@id"]
	            : collaborator["@id"],
	        })),
	        ...addOptions,
	      ]}
	      value={isMulti ? value : value.value !== "" ? value : null}
	      onInputChange={(value) => {
	        setQuery(value);
	      }}
	      isMulti={isMulti}
	      components={{ MultiValue }}
	      {...props}
	      onMenuScrollToBottom={() => {
	        if (
	          canFetchMore !== false &&
	          isFetchingMore !== true &&
	          isFetching !== true
	        ) {
	          fetchMore();
	        }
	      }}
	      isDisabled={isDisabled}
	      isLoading={!collaborators || isFetchingMore || isFetching}
	      allowSelectAll={allowSelectAll}
	    />
    ) : (
	<Select
      name={name}
      label={label}
      options={[
        ...map(collaborators, (collaborator) => ({
          label: withJob ? getFirstnameLastnameJob({ collaborator }, "collaborator") : getFirstnameLastname({ collaborator }, "collaborator"),
          value: valueId
            ? collaborator.id
            : isUser
            ? collaborator?.linkedUser?.["@id"]
            : collaborator["@id"],
        })),
        ...addOptions,
      ]}
      value={isMulti ? value : value.value !== "" ? value : null}
      onInputChange={(value) => {
        setQuery(value);
      }}
      isMulti={isMulti}
      {...props}
      onMenuScrollToBottom={() => {
        if (
          canFetchMore !== false &&
          isFetchingMore !== true &&
          isFetching !== true
        ) {
          fetchMore();
        }
      }}
      isDisabled={isDisabled}
      isLoading={!collaborators || isFetchingMore || isFetching}
      allowSelectAll={allowSelectAll}
    />
   )}
   </div>
  );
}
